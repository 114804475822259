import axios from "axios";

const baseUrl = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}`;
const no_authentication = ["/security/login"];
const axiosApiInstance = axios.create();


// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
      Accept: "*/*",
      "Content-Type": "application/json",
      "X-App-Origin": "Tecopos-Landing",
    } as any;

    const session = localStorage.getItem("session");

    const rute =
      config.url?.split(baseUrl)[1] ?? "";

    if (session !== null && !no_authentication.includes(rute)) {
      const keys = JSON.parse(session);

      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${keys.token}`,
      } as any;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const session = localStorage.getItem("session");

      if (session) {
        const keys = JSON.parse(session);
        try {
          await axios
            .post(
              `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}/security/refresh-token`,
              {
                refresh_token: keys.refresh_token,
              }
            )
            .then(async (response) => {
              const new_session = {
                token: response.data.token,
                refresh_token: response.data.refresh_token,
              };
              localStorage.setItem("session", JSON.stringify(new_session));

              axiosApiInstance.defaults.headers.common["Authorization"] =
                "Bearer " + response.data.token;
              return axiosApiInstance(originalRequest);
            })
            .catch(async (error) => {
              localStorage.removeItem("session");

              return Promise.reject(error);
            });
        } catch (e) {
          localStorage.removeItem("session");
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  }
);

const get = async (path: string) => {
  const request = {
    url: `${baseUrl+path}`,
    method: "GET",
  };

  return axiosApiInstance.get(request.url);
};

const post = async (path: string, body: object, config = {}) => {
  const request = {
    url: `${baseUrl + path}`,
    method: "POST",
  };

  return axiosApiInstance.post(request.url, body, config);
};

const put = async (path: string, body: object) => {
  const request = {
    url: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}${path}`,
    method: "PUT",
  };

  return axiosApiInstance.put(request.url, body);
};

const patch = async (path: string, body: object) => {
  const request = {
    url: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}${path}`,
    method: "PATCH",
  };

  return axiosApiInstance.patch(request.url, body);
};

const deleteAPI = async (path: string, body: object) => {
  const request = {
    url: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}${path}`,
    method: "DELETE",
  };

  return axiosApiInstance.delete(request.url, { data: body });
};

export default{
  get,
  post,
  put,
  patch,
  deleteAPI,
};