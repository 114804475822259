import HeroGif from '../../assets/gifs/1new.gif'
import LogoWhite from '../../assets/images/logo-blanco .png'
import ImageComponent from '../misc/Image'

export function Hero() {

    return (
        <div className="bg-gray-50 flex justify-end lg:h-[80vh] overflow-hidden">
            <div className='flex flex-col lg:flex-row w-full h-full px-4 xl:px-56 pt-20 pb-16 bg-[#541554] '>
                <div className='flex flex-col justify-center gap-14 lg:w-1/2 sm:p-8' >

                    <div className='w-1/2 '>
                        <img src={LogoWhite} alt="logo tecopos white" />
                    </div>

                    <div className='flex'>
                        <span className='text-white text-2xl 2xl:text-4xl font-semibold' >
                            Para los que se cansaron de los cientos
                            de <span className='text-[#ea5e28]'>(IPV)</span>  en papel con <span className='line-through'>TACHADURAS</span> y
                            escritura que no las entiende ni “Dios”
                            (O) de nuestro gran amigo EXCEL de
                            inventarios con más versiones que un
                            sistema operativo y nombres creativos,
                            como :) “Final-1.1”*“Final-Final-Final-1.2”...
                        </span>
                    </div>

                </div>

                <div className='flex h-full lg:w-1/2 lg:jusify-center'>
                    <div className='flex sm:p-8 w-full h-[343px] md:h-[717px] lg:h-[437px] xl:h-[430px] 2xl:h-[625px]'>
                    <ImageComponent
                        className='w-full'
                        src={HeroGif}
                        hash={"|aHnN$~qF2WAi^IURPW;RjE2IUR5WWt7t7ozs:ay8xD%ozofoKt7ofWBt7w^tRtljZRjaeaeRjj[KPoLwHR*X8j[W;oeae-ptRS3ofjaRjV@ofa{IpRjr?jZShofoffkoLNwoLs9WCWXkCkCf5axr?RjNaoekCj[oKbHWp"}
                    />
                    </div>
                </div>

            </div>
        </div>
    )
}
