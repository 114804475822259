import clsx from 'clsx'
import { ReactNode } from 'react';

const formClasses =
    'block w-full appearance-none rounded-lg border border-gray-200 bg-white py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-gray-900 placeholder:text-gray-400 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm'

interface IFieldProps {
    id?: string;
    children?: ReactNode;
    label?: string;
    type?: string;
    className?: string;
    placeholder?: string;
    name?: string;
    onChange?: Function;
    autoComplete?: string;
    required?: boolean;
    labelClass?: string;
    inputProps?: any
    options?: any[];
    error?: string;
}

interface ILabelProps {
    id?: string;
    children?: ReactNode;
    className?: string;
}

function Label({ id, children, className = "mb-2 block text-sm font-semibold text-gray-900" }: ILabelProps) {
    return (
        <label
            htmlFor={id}
            className={className}
        >
            {children}
        </label>
    )
}

export function TextField({ id, label, className, labelClass, inputProps, error }: IFieldProps) {

    return (
        <div>
            {label && <Label className={labelClass} id={id}>{label}</Label>}
            <div className="mt-1">
                <input {...inputProps} id={id} className={className} />
            </div>
            {error && (<span className="text-red-600 font-bold">*{error}</span>)}

        </div>
    )
}

export function SelectField({ id, label, className, labelClass, inputProps, options }: IFieldProps) {
    return (
        <div className={className}>
            {label && <Label className={labelClass} id={id}>{label}</Label>}
            <div className="mt-1">
                <select {...inputProps} className={clsx(className ? className : formClasses, 'pr-8')}>
                    <option value="">Seleccione ... </option>
                    {options?.map((option) => (
                        <option key={option.id} value={Number(option.id)}>
                            {option.name}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}
