
interface CallActionProps {
    color?: 'beige'
}

export function CallToAction( { color }:CallActionProps ) {
    return (
        <div className={`w-full justify-center py-10 my-8 flex bg-${color ? '[#f4ede4]': 'white'  } rounded-xl`} >
            <div className="flex w-[80%] flex-col gap-8">
                <p className="text-lg md:text-2xl font-bold text-center" ><i>"Está bien, me has convencido, quiero saber más, ¿qué hago?"</i></p>
                <p className="text-xs md:text-xl font-normal text-center" >Da click en el botón de abajo y solo escribe “Tecopos”, para que uno de nuestros
                    expertos te atienda.
                </p>
                <button onClick={()=> window.open(`https://api.whatsapp.com/send?phone=${+5350649337}&text=${"Hola Tecopos"}`) }
                    className="bg-[#ea5e28] text-white text-base md:text-xl font-semibold h-[5rem] rounded-md">CHATEAR AHORA</button>
            </div>
        </div>
    )
}
