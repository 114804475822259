import logo from "../../assets/images/logo.png";
import { LogomarkAnimate } from "../atoms/Logo";

interface ILogomarkProps {
    width?: string,
    height?: string,
    className?: string,
}

interface ILogoProps {
    className?: string
    animate: boolean
}


export function Logomark(props: ILogomarkProps) {
    return (
        <img alt="Workflow" className="h-8 sm:h-10" src={logo} />
    )

}
export function Logo(props: ILogoProps) {
    return (
        <div className="flex items-center justify-center">
            {props.animate ? <LogomarkAnimate /> : <Logomark />}
            <span className="ml-4 text-2xl font-medium">Tecopos</span>
        </div >

    )
}