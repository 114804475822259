import { INewBusiness } from "../interfaces/interfaces";
import query from "../services/APIServer";

export const useServer = () => {

    const addBusiness = (data: INewBusiness) => {
        return new Promise(resolve => {
            query.post("/landing/business", data)
                .then(() => {
                    resolve({ ready: true, error: null })
                })
                .catch((error) => {
                    let errorMsg = error.response?.data?.message ?? "";
                    
                    resolve({ ready: false, error: errorMsg })
                });
        })

    }

    return {
        addBusiness
    }
}
