import './App.css';
import { WrapperAnalytics } from './component/containers/WrapperAnalytics';
import { BusinessCtx } from './context/BusinessContext';
import { useAnalytics } from './hooks/useAnalytics';
import { MainRouter } from './routes/MainRouter';

function App() {
  const { initialized } = useAnalytics();

  return (
    <WrapperAnalytics initialized={initialized}>
      <BusinessCtx>         
        <MainRouter />
      </BusinessCtx>
    </WrapperAnalytics>
  );
}

export default App;
