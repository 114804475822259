import { AuthLayout } from '../../component/containers/AuthLayout';
import { Button } from '../../component/atoms/Button';
import { TextField } from '../../component/atoms/Fields';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';


export default function Login() {

    const sitekey: string = process.env.REACT_APP_SITE_KEY as string;

    return (
        <>
            <AuthLayout
                title="Entrar a tu cuenta"
                subtitle={
                    <>
                        ¿No tienes una cuenta?{' '}
                        <Link to="/register" className="text-cyan-600">
                            Regístrate aquí
                        </Link>{' '}
                        de forma gratuita.
                    </>
                }
            >
                <form>

                    <div className="space-y-6">
                        <TextField
                            onChange={() => console.log('test')}
                            label="Email address"
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                        />
                        <TextField
                            onChange={() => console.log('test')}
                            label="Password"
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                        />
                        <ReCAPTCHA sitekey={sitekey}/>
                        
                    </div>
                    <Button variant="solid" type="submit" color="cyan" className="mt-8 w-full">
                        Entrar
                    </Button>
                </form>

            </AuthLayout>
        </>
    )
}
