import { ReactNode } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom';

interface IColor {
    cyan?: string,
    white?: string,
    gray?: string,
    outlineGray?: string,
    orange?: string,
}

interface IButtonProps {
    variant: keyof IStyle,
    color: keyof IColor,
    className?: string,
    href?: string,
    ref?: string,
    isLink?: boolean,
    children?: ReactNode,
    type?: string,
}

interface IStyle {
    solid?: string;
    outline?: string;
}

const baseStyles: IStyle = {
    solid:
        'inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors',
    outline:
        'inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors',
}

const variantStyles: IColor = {
    cyan: 'relative overflow-hidden bg-cyan-500 text-white before:absolute before:inset-0 active:before:bg-transparent hover:before:bg-white/10 active:bg-cyan-600 active:text-white/80 before:transition-colors',
    white:
        'bg-white text-cyan-900 hover:bg-white/90 active:bg-white/90 active:text-cyan-900/70',
    gray: 'bg-gray-800 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white/80',
    outlineGray: 'border-gray-300 text-gray-700 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80',
    orange: 'bg-orange-600 text-white hover:bg-orange-500 active:bg-orange-500 active:text-white/80'
}

export const Button = (props: IButtonProps) => {

    let className = clsx(
        baseStyles[props?.variant],
        variantStyles[props?.color],
        props.className
    )


    return props?.href ? props?.isLink ?
        (
            <Link to={props.href} className={className}> {props?.children}</Link>
        ) : (
            <a
                href={props.href}
                target="_blank"
                rel="noreferrer"
                className={className}
            >{props?.children}</a>) :
        (
            <button ref={props.ref} className={className}>{props?.children}</button>
        )
};
