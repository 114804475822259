import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Link } from 'react-router-dom'


interface INavProp {
    head: boolean
}
export function NavLinks(props: INavProp) {
    let [hoveredIndex] = useState(null)

    const Links = props.head ? [
        ['Modalidades', '#features', false],
        ['Planes', '#pricing', false],
        //['Negocios', '/bussines', true],
    ] : [
        ['Planes', '#pricing', false],
        ['Términos y condiciones', '/conditions', true],
        ['Política de privacidad', '/policy', true],
    ]
    return <>
        {Links.map(([label, href, isPath], index) => (
            !isPath ? (<a
                href={href as string}
                className="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors delay-150 hover:bg-gray-100 hover:text-gray-900 hover:delay-[0ms]"
            >
                <AnimatePresence>
                    {hoveredIndex === index && (
                        <motion.span
                            className="absolute inset-0 rounded-lg bg-gray-100"
                            layoutId="hoverBackground"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 0.15 } }}
                            exit={{
                                opacity: 0,
                                transition: { duration: 0.15, delay: 0.2 },
                            }}
                        />
                    )}
                </AnimatePresence>
                <span className="relative z-10">{label}</span>
            </a >) : (
                <Link
                    to={href as string}
                    className="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors delay-150 hover:bg-gray-100 hover:text-gray-900 hover:delay-[0ms]"
                >
                    <AnimatePresence>
                        {hoveredIndex === index && (
                            <motion.span
                                className="absolute inset-0 rounded-lg bg-gray-100"
                                layoutId="hoverBackground"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1, transition: { duration: 0.15 } }}
                                exit={{
                                    opacity: 0,
                                    transition: { duration: 0.15, delay: 0.2 },
                                }}
                            />
                        )}
                    </AnimatePresence>
                    <span className="relative z-10">{label}</span>
                </Link >)

        ))}
    </>
}
