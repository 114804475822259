import logo from "../../assets/images/logoLoading.png";

export function LogomarkAnimate() {

    return (
        <div className='flex justify-center items-center'>
            <img
                className="h-7 sm:h-9"
                src={logo}
                alt=""
            />
            <div className='bg-orange-600 animate-ping h-2 w-2 rounded-full absolute mt-4 ml-4' />
        </div>
    )
}