import { createContext, ReactNode, useEffect, useState } from "react";
import { IBusinessCategory, IBusinessMunicipe, IBusinessProvince } from "../interfaces/interfaces";
import query from "../services/APIServer";

interface IBusinesProps {
    children: ReactNode;
}

interface ContextTypes {
    businessCategorys: Array<IBusinessCategory> | null;
    provinces: Array<IBusinessProvince> | null;
    municipalities: Array<IBusinessMunicipe> | null;
}

const initialValues = {
    businessCategorys: null,
    provinces: null,
    municipalities: null,
}

export const BusinessContext = createContext<ContextTypes>(initialValues);

export const BusinessCtx = ({ children }: IBusinesProps) => {
    const [ctxState, setCtxState] = useState({
        businessCategorys: null,
        provinces: null,
        municipalities: null,
    });

    const initialLoad = async () => {
        try {
            const municipalities = await query.get("/public/municipalities?all_data=true");
            const provinces = await query.get("/public/provinces");
            const categorys = await query.get("/public/businesscategories");

            setCtxState({
                businessCategorys:categorys.data?.items,
                provinces: provinces.data.items,
                municipalities: municipalities.data.items
            })
            
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        initialLoad();
    }, [])


    return (
        <BusinessContext.Provider
            value={{
                businessCategorys: ctxState.businessCategorys,
                provinces: ctxState.provinces,
                municipalities: ctxState.municipalities
            }}
        >
            {children}
        </BusinessContext.Provider>
    );
};
