import { useContext, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Flip, toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { BusinessContext } from "../../context/BusinessContext";
import { useServer } from "../../hooks/useServer";
import { BasicType, INewBusiness, SelectInterface } from "../../interfaces/interfaces";
import Loading from "../atoms/Loading";
import SubmitCard from "../atoms/SubmitCard";
import AsyncComboBox from "./AsyncCombobox";
import Input from "./Input";
import Select from "./Select";

export default function BussinesForm() {
  const { addBusiness } = useServer();
  const [isRobot, setIsRobot] = useState(true)
  const [isSucces, setIsSucces] = useState(false)
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    control
  } = useForm();

  const { businessCategorys } = useContext(BusinessContext);
  const siteKeyCaptcha: string = process.env.REACT_APP_SITE_KEY as string;
  const [tokenCaptcha, setTokenCaptcha] = useState("");

  const onChangeCaptcha = (value: any) => {
    setTokenCaptcha(value);
  }


  const handleSubmitForm = async (businessData: INewBusiness) => {
    setLoading(true);

    if (tokenCaptcha) {

      const business: INewBusiness = {
        ...businessData,
        token: tokenCaptcha
      }
      const payload: any = await addBusiness(business);

      if (payload.ready) {
        setIsSucces(true);
        reset();
      } else {
        let errorMsg = "";
        if (payload.error.response?.data?.message) {
          errorMsg = payload.error.response?.data?.message
        } else {
          errorMsg = "Ha ocurrido un error. Contacte al administrador";
        }
        toast.error(errorMsg, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip
        });
      }
      setTokenCaptcha("");
      setIsRobot(true);
    } else {
      setIsRobot(false);
    }
    setIsRobot(false);
  }

  const onSubmit= async(data: INewBusiness) => {
    setLoading(true);
    if (tokenCaptcha) {

      const business: INewBusiness= {
        ...data,
        token: tokenCaptcha
      }

      const payload: any = await addBusiness(business);

      if (payload.ready) {
        setIsSucces(true);
        reset();
      } else {
        let errorMsg = "";
        if (payload.error.response?.data?.message) {
          errorMsg = payload.error.response?.data?.message
        } else {
          errorMsg = "Ha ocurrido un error. Contacte al administrador";
        }
        toast.error(errorMsg, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip
        });
      }
      setTokenCaptcha("");
      setIsRobot(true);
    } else {
      setIsRobot(false);
    }
    setLoading(false);
  };

  const businessCategoriesToSelect: SelectInterface[] = businessCategorys?.map(element => {
    return {
      id: element?.id,
      name: element?.name ?? "",
    }
  }) || []

  //@ts-ignore
  const provinceId = watch("address.provinceId")

  return (
    <div className="bg-gray-50">

      <main className="overflow-hidden bg-gray-50">
        {/* Header */}
        <div className="bg-warm-gray-50">
          <div className="py-10 lg:py-10">
            <div className="relative z-10 mx-auto max-w-7xl pl-4 pr-8 sm:px-6 lg:px-8">
              <h1 className="text-4xl font-bold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl">
                ¡Crea tu negocio!
              </h1>
              <p className="mt-6 max-w-3xl text-xl text-warm-gray-500">
                Registra tu negocio llenando los datos del siguiente formulario
              </p>
              <div className="mt-6">
                <Link to="/" className="text-base font-medium text-orange-600 hover:text-orange-500">
                  Volver al Inicio
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Data section */}
        <section className="relative bg-gray-50" aria-labelledby="contact-heading">
          <div className="absolute h-1/2 w-full bg-warm-gray-50" aria-hidden="true" />
          {/* Decorative dot pattern */}
          <div className="relative bg-gray-50 mx-auto max-w-7xl px-6 lg:px-8">
            <svg
              className="absolute top-0 right-0 z-0 -translate-y-16 translate-x-1/2 transform sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-warm-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
            </svg>
          </div>
          <div className="mx-auto bg-gray-50 max-w-7xl pb-10 px-6 lg:px-8">
            <div className="relative bg-white shadow-xl rounded-xl">


              <div className="grid grid-cols-1 lg:grid-cols-2">

                <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                  {/* Data Bussines form */}
                  {loading ? <Loading /> :
                    isSucces ? <SubmitCard succes={isSucces} className="h-60 text-orange-600 w-60" /> : (
                      //@ts-ignore
                      <form onSubmit={handleSubmit(onSubmit)} className="mt-6">

                        <div className="mt-6">

                          <h3 className="text-xl mt-2 col-span-2 w-full font-medium leading-6 text-gray-900">Datos del Negocio</h3>
                          <div className="grid grid-cols-1 mt-3 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                            <h3 className="text-lg mt-2 col-span-2 w-full font-medium leading-6 text-gray-600">Detalles</h3>


                            <Input
                              name="name"
                              control={control}
                              label="Nombre"
                              rules={{ required: "Este campo es requerido" }}
                            />

                            <Select
                              name="businessCategoryId"
                              data={businessCategoriesToSelect}
                              label="Categoría"
                              control={control}
                              rules={{ required: "Campo requerido" }}
                            />

                          </div>
                          <div className="grid grid-cols-1 mt-2 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                            <h3 className="text-lg mt-2 col-span-2 w-full font-medium leading-6 text-gray-600">Dirección</h3>

                            <Input
                              name="street"
                              control={control}
                              label="Calle"
                              rules={{ required: "Este campo es requerido" }}
                            />

                            <Input
                              name="locality"
                              control={control}
                              label="Localidad"
                              rules={{ required: "Este campo es requerido" }}
                            />

                            <AsyncComboBox
                              name="provinceId"
                              dataQuery={{ url: "/public/provinces" }}
                              normalizeData={{ id: "id", name: "name" }}
                              //@ts-ignore
                              control={control}
                              dependendValue={{ countryId: 54 }}
                              label="Provincia"
                            />

                            <AsyncComboBox
                              name="municipalityId"
                              dataQuery={{
                                url: "/public/municipalities",
                              }}
                              normalizeData={{ id: "id", name: "name" }}
                              //@ts-ignore
                              dependendValue={{ provinceId }}
                              //@ts-ignore
                              control={control}
                              label="Municipio"
                            />

                          </div>


                        </div>

                        <div className="grid mt-6 grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                          <h3 className="text-xl mt-2 col-span-2 w-full font-medium leading-6 text-gray-900">Datos del Propiertario</h3>
                          <div className="col-span-2 sm:col-span-1">

                            <Input
                              name="displayName"
                              control={control}
                              label="Nombre y Apellidos"
                              rules={{ required: "Este campo es requerido" }}
                            />
                          </div>

                          <div className="col-span-2 sm:col-span-1">

                            <Input
                              name="telephone"
                              control={control}
                              label="Teléfono"
                              type="number"
                              rules={{ required: "Este campo es requerido" }}
                            />
                          </div>
                          <div className="col-span-2 sm:col-span-1">
                            <Input
                              name="email"
                              control={control}
                              label="Correo Electrónico"
                              rules={{ required: "Este campo es requerido" }}
                            />
                          </div>

                        </div>
                        <div className="grid grid-cols-1 mt-4 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                          <div className="col-span-2 sm:col-span-1">
                            <ReCAPTCHA sitekey={siteKeyCaptcha} onError={(e) => console.log(e)
                            } onChange={onChangeCaptcha} />
                            {!isRobot && (<span className="text-red-600">*{"Verifique que no es un robot"}</span>)}
                          </div>

                        </div>
                        <div className="sm:col-span-2 sm:flex sm:justify-end">
                          <button
                            type="submit"
                            className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-orange-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:w-auto"
                          >
                            <span>{loading ? "Creando..." : "Crear"}</span>
                          </button>
                        </div>
                      </form>
                    )}
                </div>
              </div>
            </div>
          </div>
        </section >
      </main >
      <ToastContainer />
    </div >
  )
}
