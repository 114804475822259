import { Link } from "react-router-dom";

interface ISuccesCard {
    className?: string;
    succes?: boolean;
    error?: string;
}

interface IErrorMessage {
    error?: string
}

interface IIcon {
    className?: string;
}
const SuccesIcon = ({ className }: IIcon) => {
    return (
        <svg fill="#13eac0" className={className} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512" xmlSpace="preserve">
            <g>
                <path d="M474.045,173.813c-4.201,1.371-6.494,5.888-5.123,10.088c7.571,23.199,11.411,47.457,11.411,72.1
c0,62.014-24.149,120.315-68,164.166s-102.153,68-164.167,68s-120.316-24.149-164.167-68S16,318.014,16,256
S40.149,135.684,84,91.833s102.153-68,164.167-68c32.889,0,64.668,6.734,94.455,20.017c28.781,12.834,54.287,31.108,75.81,54.315
c3.004,3.239,8.066,3.431,11.306,0.425c3.24-3.004,3.43-8.065,0.426-11.306c-23-24.799-50.26-44.328-81.024-58.047
C317.287,15.035,283.316,7.833,248.167,7.833c-66.288,0-128.608,25.813-175.48,72.687C25.814,127.392,0,189.712,0,256
c0,66.287,25.814,128.607,72.687,175.479c46.872,46.873,109.192,72.687,175.48,72.687s128.608-25.813,175.48-72.687
c46.873-46.872,72.687-109.192,72.687-175.479c0-26.332-4.105-52.26-12.201-77.064
C482.762,174.736,478.245,172.445,474.045,173.813z"/>
                <path d="M504.969,83.262c-4.532-4.538-10.563-7.037-16.98-7.037s-12.448,2.499-16.978,7.034l-7.161,7.161
c-3.124,3.124-3.124,8.189,0,11.313c3.124,3.123,8.19,3.124,11.314-0.001l7.164-7.164c1.51-1.512,3.52-2.344,5.66-2.344
s4.15,0.832,5.664,2.348c1.514,1.514,2.348,3.524,2.348,5.663s-0.834,4.149-2.348,5.663L217.802,381.75
c-1.51,1.512-3.52,2.344-5.66,2.344s-4.15-0.832-5.664-2.348L98.747,274.015c-1.514-1.514-2.348-3.524-2.348-5.663
c0-2.138,0.834-4.149,2.351-5.667c1.51-1.512,3.52-2.344,5.66-2.344s4.15,0.832,5.664,2.348l96.411,96.411
c1.5,1.5,3.535,2.343,5.657,2.343s4.157-0.843,5.657-2.343l234.849-234.849c3.125-3.125,3.125-8.189,0-11.314
c-3.124-3.123-8.189-3.123-11.313,0L212.142,342.129l-90.75-90.751c-4.533-4.538-10.563-7.037-16.98-7.037
s-12.448,2.499-16.978,7.034c-4.536,4.536-7.034,10.565-7.034,16.977c0,6.412,2.498,12.441,7.034,16.978l107.728,107.728
c4.532,4.538,10.563,7.037,16.98,7.037c6.417,0,12.448-2.499,16.977-7.033l275.847-275.848c4.536-4.536,7.034-10.565,7.034-16.978
S509.502,87.794,504.969,83.262z"/>
            </g>
        </svg>

    );
}
/*const ErrorIcon = ({ className }: IIcon) => {

    return (
        <svg className={className} viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="add" fill="#c12127" transform="translate(42.666667, 42.666667)">
                    <path d="M213.333333,3.55271368e-14 C331.136,3.55271368e-14 426.666667,95.5306667 426.666667,213.333333 C426.666667,331.136 331.136,426.666667 213.333333,426.666667 C95.5306667,426.666667 3.55271368e-14,331.136 3.55271368e-14,213.333333 C3.55271368e-14,95.5306667 95.5306667,3.55271368e-14 213.333333,3.55271368e-14 Z M213.333333,42.6666667 C119.232,42.6666667 42.6666667,119.232 42.6666667,213.333333 C42.6666667,307.434667 119.232,384 213.333333,384 C307.434667,384 384,307.434667 384,213.333333 C384,119.232 307.434667,42.6666667 213.333333,42.6666667 Z M262.250667,134.250667 L292.416,164.416 L243.498667,213.333333 L292.416,262.250667 L262.250667,292.416 L213.333333,243.498667 L164.416,292.416 L134.250667,262.250667 L183.168,213.333333 L134.250667,164.416 L164.416,134.250667 L213.333333,183.168 L262.250667,134.250667 Z" id="error">

                    </path>
                </g>
            </g>
        </svg>
    )
}*/

const SuccesMessage = () => {
    return (
        <div>
            <h2 className="text-xl font-bold tracking-tight text-warm-gray-900 sm:text-2xl lg:text-2xl">
                ¡Su negocio a sido creado satisfactoriamente, le hemos enviado vía electrónica las indicaciones con los próximos pasos!
                <br />
                <br />
                Ante cualquier duda, contáctenos
                <br /><br />
                Equipo TECOPOS
            </h2>
        </div>
    );
}

const ErrorMessage = ({ error }: IErrorMessage) => {
    return (
        <h2 className="text-xl font-bold tracking-tight text-warm-gray-900 sm:text-2xl lg:text-2xl">
            {error}
        </h2>
    );
}
export default function SubmitCard({ className, succes, error }: ISuccesCard) {
    return (
        <div className="flex flex-col items-center justify-start rounded-2xl bg-white">
            <div className="relative flex-1 px-6 pt-16 pb-8 md:px-8">
                <SuccesIcon className={className} />
            </div>
            <div className="mt-4 mx-auto px-10 md:px-40">
                {succes ? <SuccesMessage /> : <ErrorMessage error={error} />}
            </div>
            <div className="mt-6">
                <Link to="/" className="text-base font-medium text-orange-600 hover:text-orange-500">
                    Volver al Inicio
                    <span aria-hidden="true"> &rarr;</span>
                </Link>
            </div>

        </div>
    );
}
