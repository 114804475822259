import SecoundSectionGif from '../../assets/gifs/4new.gif'
import ImageComponent from '../misc/Image'
import { CallToAction } from './CallToAction'

export function SecondaryFeatures() {
    return (
        <section className="flex flex-col w-full px-4 pb-16 xl:px-52 bg-white" >
            <div className='mt-16'>
                <p className='text-lg md:text-2xl font-semibold text-center'>
                    <p>Verás,<br /> una vez vi una frase del empresario estadounidense Jim Rohn donde decía lo siguiente:</p>
                    <p className='font-bold my-8'><i>
                        “El tiempo es nuestro activo más valioso. Y sin embargo, tendemos a desperdiciarlo, matarlo y gastarlo en lugar de invertirlo”
                    </i></p>
                    <p> Si has llegado hasta aquí te doy las gracias por tu atención, todos sabemos que hacer las cosas manualmente te roba mucho tiempo, pero
                    </p>
                    <br />
                    <p>
                        ¿qué pasa si tienes cierta capa digital y aún así se te escapan horas y horas?
                    </p>
                    <br />
                    <p>
                        Bien, eso era lo que le pasaba a algunos cliente que aunque tenían un sistema para llevar sus ventas digitalmente, al final seguían
                        perdiendo mucho tiempo por nuestro amigo <span className='text-[#ea5e28] font-bold'> IPV. </span>
                    </p>
                    <br />
                    <p>
                        Mira,
                    </p>

                    <p>
                        <span className='text-[#ea5e28] font-bold'>El mercado no perdona </span> y con todo esto de la p... inflación de la monedas extranjeras es una
                        locura todos los días cambiar precios a cientos de productos,
                        y es otra locura mayor llevar tus costos en una moneda y vender en otra
                    </p>
                    <br />

                    <p> ¡Candela esta parte...! <br /> brindamos un sistema de tasa de cambio con las monedas que utilizas, las que a su vez están vinculadas con tus precios de venta, costo de productos, inventarios, predicciones de ventas en múltiples monedas... es decir, un tremendo arroz con mango :-).</p>
                </p>
            </div>

            <div className='h-[228px] md:h-[490px] lg:h-[580px] xl:h-[595px] 2xl:h-[900px] ' >
                <ImageComponent
                    src={SecoundSectionGif}
                    className='w-full'
                    hash={'|IQ,8q9]8_Rj-;?v~W_N_3vzyXt7eTkqRPM{RjRj?H-;.8iccEIAIVD%IU-;RjR*VsX9niofofof~qoLRPo}RPbbM{bbM{%#MwMxb^VstRs:xusn.SVYRjo}R5kDWBj[aeEMVss:o~nNkWozaxofS$emofo}aJbbj[WBfk'}
                />
            </div>

            <CallToAction color='beige' />
        </section>
    )
}
