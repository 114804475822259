import { useEffect, useState } from "react";
import ReactGA from "react-ga";

export function useAnalytics() {
    const [initialized, setInitialized] = useState(false);
    const keyGa: string = process.env.REACT_APP_GA_KEY as string;

    useEffect(() => {
        if (window.location.href.includes('localhost')) {
            ReactGA.initialize(keyGa);
            setInitialized(true);
        }
    }, [keyGa])

    return {
        initialized
    }
}