import image from "../../assets/images/logoLoading.png";

export default function Loading() {
    return (
        <div className='flex h-screen w-full justify-center items-center '>
            <img
                className="h-32 w-32"
                src={image}
                alt=""
            />
            <div className='bg-orange-600 animate-ping h-7 w-7 rounded-full absolute mt-14 ml-16' />
        </div>
    )
}