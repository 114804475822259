import { Fade } from "react-awesome-reveal";
import { CallToAction } from "../../component/organism/CallToAction";
import { Footer } from "../../component/organism/Footer";
import { Hero } from "../../component/organism/Hero";
import { PrimaryFeatures } from "../../component/organism/PrimaryFeatures";
import { SecondaryFeatures } from "../../component/organism/SecondaryFeatures";
import { ThirdsFeatures } from "../../component/organism/ThirdsFeatures";
import { FourthFeatures } from "../../component/organism/FourthFeature";
import { FifthFeatures } from "../../component/organism/FifthFeatures";



export const Home = () => {
    return (
        <main>
            <Hero />

            <Fade direction="left" triggerOnce={true}>
                <PrimaryFeatures />
            </Fade>
            
            <Fade direction="right" triggerOnce={true}>
                <SecondaryFeatures />
            </Fade>
            <Fade direction="left" triggerOnce={true}>
                <ThirdsFeatures />
            </Fade>
            <Fade direction="right" triggerOnce={true}>
                <FourthFeatures />
            </Fade>
            <Fade direction="left" triggerOnce={true}>
                <FifthFeatures />
            </Fade>

            <Footer />

        </main >
    );
}
