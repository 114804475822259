import { Link } from "react-router-dom";
import { Logomark } from "../../component/molecules/Logos";

export default function NotFoundPage() {
    return (
        <>
            <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
                <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
                    <div className="flex flex-shrink-0 justify-center">
                        <Link to="/" className="inline-flex">
                            <span className="sr-only">Your Company</span>
                            <Logomark/>
                        </Link>
                    </div>
                    <div className="py-16">
                        <div className="text-center">
                            <p className="text-base font-semibold text-orange-600">404</p>
                            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Esta página no funciona.</h1>
                            <p className="mt-2 text-base text-gray-500">Lo sentimos, no se pudo encontrar esta página para ti.</p>
                            <div className="mt-6">
                                <Link to="/" className="text-base font-medium text-orange-600 hover:text-orange-500">
                                    Volver al Inicio
                                    <span aria-hidden="true"> &rarr;</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
