import FourthSectionGif from '../../assets/gifs/7new.gif'
import ImageComponent from '../misc/Image'
import { CallToAction } from './CallToAction'

export function FourthFeatures() {
  return (
    <section className="flex flex-col w-full px-4 pb-16 xl:px-52 bg-white" >
      <div className='mt-16'>
        <p className='text-lg md:text-2xl font-semibold text-center'>
          <p>No te voy a contar todos los beneficios que tiene una <span className='text-[#ea5e28] font-bold' > tienda en línea</span>.</p> <br />

          <p>Siempre me ha gustado decir que si quieres seguir pescando en una laguna <span className='text-[#ea5e28] font-bold'> (tu zona) </span> no es que esté mal, pero si quieres tener esos peces
            gordos que están esperando para que alguien los pesques, tendrás que irte para el océano <span className='text-[#ea5e28] font-bold'> (el Internet)</span>.</p> <br />

          <p>Tú te lo pierdes, ahora mismo puedes estar facturando mientra estás durmiendo, ya que <span className='text-[#ea5e28] font-bold'> Tropipay </span> hace ese trabajo por ti.
            Si no sabes que caraj...o es <span className='text-[#ea5e28] font-bold'> Tropipay </span> pincha de una vez el botón naranja. </p>
        </p>
      </div>

      <div className='h-[228px] md:h-[490px] lg:h-[580px] xl:h-[595px] 2xl:h-[900px] ' >
        <ImageComponent
          src={FourthSectionGif}
          className='w-full'
          hash={'|oN^JFrqoz%go}R+ofjuxaR4RPbckqkDj[oLofoL~pa~o}Nbayn$V@j[R*xut7ozozfkV@V@V@WB?bRjM{t7bGayjZaykCo}f*aeV@f6j[ayfkf+IotRs:Rjf6fkWBaeWVkqX8V@aKf5j[oLjsofIobbV@jEf6j[ofj[of'}
        />
      </div>

      <CallToAction color='beige' />
    </section>
  )
}
