import { PropsWithChildren, useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

interface IWrapperProps {
    initialized: boolean,
    children: PropsWithChildren<any>,
}

export function WrapperAnalytics(props: IWrapperProps) {
    const location = useLocation();

    useEffect(() => {
        if (props.initialized) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [props.initialized, location])

    return props.children
}