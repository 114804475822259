import { Route, Routes} from "react-router-dom";
import CreateBussinesPage from "../pages/bussines/CreatBussines";
import { Home } from "../pages/home/Home";
import PrivatePolicyLayout from "../pages/policy/PrivatePolicyLayout";
import Login from "../pages/security/Loguin";
import NotFoundPage from "../pages/security/NotFoundPage";
import Register from "../pages/security/Registrer";
import LayoutTaC from "../pages/termandconditions/LayoutTermandCond";

export const MainRouter = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/bussines" element={<CreateBussinesPage />} />
                <Route path="/register" element={<Register />} />
                <Route path="/conditions" element={<LayoutTaC />} />
                <Route path="/policy" element={<PrivatePolicyLayout />} />
                <Route path="/*" element={<NotFoundPage />} />
            </Routes>
        </>
    );
};
