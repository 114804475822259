
import ScrollToTop from "react-scroll-to-top";
import { Footer } from "../../component/organism/Footer";
import { Header } from "../../component/organism/Header";
import TermAndCond from "./TermAndCond";

export default function LayoutTaC() {

    return (
        <main className="bg-gray-50">
            <Header navLinkHide={true} />
            <div className="mx-auto flex w-full max-w-7xl flex-col px-4 sm:px-6">
                <TermAndCond />
            </div>
            <ScrollToTop smooth style={{ backgroundColor: "#ea580c", borderRadius: "50%" }} color="white" className="hover:bg-orange-500 focus:ring-orange-500 inline-flex items-center rounded-full p-3" />
            <Footer hideFooter={true} />
        </main>

    );
} 
