import { AuthLayout } from '../../component/containers/AuthLayout';
import { Button } from '../../component/atoms/Button';
import { TextField } from '../../component/atoms/Fields';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

export default function Register() {

    const siteKey: string = process.env.REACT_APP_SITE_KEY as string;

    return (
        <>
            <AuthLayout
                title="Registarte en TECOPOS"
                subtitle={
                    <>
                        ¿Ya estas registrado?{' '}
                        <Link to="/login" className="text-cyan-600">
                            Entra
                        </Link>{' '}
                        a tu cuenta
                    </>
                }
            >
                <form>
                    <div className="grid grid-cols-2 gap-6">
                        <TextField
                            onChange={() => console.log('test')}
                            label="Nombre"
                            id="first_name"
                            name="first_name"
                            type="text"
                            autoComplete="given-name"
                            required
                        />
                        <TextField
                            onChange={() => console.log('test')}
                            label="Apellidos"
                            id="last_name"
                            name="last_name"
                            type="text"
                            autoComplete="family-name"
                            required
                        />
                        <TextField
                            onChange={() => console.log('test')}
                            className="col-span-full"
                            label="Correo electrónico"
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                        />
                        <TextField
                            onChange={() => console.log('test')}
                            className="col-span-full"
                            label="Contraseña"
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="new-password"
                            required
                        />
                        <ReCAPTCHA sitekey={siteKey} />
                    </div>
                    <Button variant="solid" type="submit" color="cyan" className="mt-8 w-full">
                        Registrarme
                    </Button>
                </form>
            </AuthLayout>
        </>
    )
}
