import FormBussines from "../../component/forms/BussinesForm";
import { Footer } from "../../component/organism/Footer";
import { Header } from "../../component/organism/Header";

export default function CreateBussinesPage() {

    return (
        <main>
            <Header navLinkHide={true}/>

            <FormBussines />
            <Footer hideFooter={true} />
        </main>

    );
} 
