import { Link } from "react-router-dom";

export default function TermAndCond() {
    return (
        <div className="relative overflow-hidden bg-gray-50 py-16">
            <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
                <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-orange-600" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-orange-600" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                    </svg>
                </div>
            </div>
            <div className="relative px-6 lg:px-8">
                <div className="mx-auto max-w-prose text-lg">
                    <h1>
                        <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                            Términos y Condiciones
                        </span>
                    </h1>

                </div>
                <div className="prose prose-lg prose-indigo mx-auto mt-10 text-gray-500">

                    <h2> 1-) Aceptación de los términos</h2>
                    <p className="mt-8 text-xl text-gray-500">
                        Al utilizar los servicios de TECOPOS, usted acepta los términos y condiciones establecidos en este documento. Si no está de acuerdo con estos términos y condiciones, no debe utilizar nuestros servicios.
                    </p>

                    <h2 className="mt-2 mb-1">2-) Descripción de servicios</h2>
                    <p className="mt-8 text-xl text-gray-500">
                        TECOPOS proporciona una plataforma de punto de venta en línea y en modo local para pequeños y medianos negocios. Ofrecemos una variedad de herramientas y funcionalidades para ayudar a los propietarios de negocios a administrar sus operaciones de venta, inventarios y atención al cliente. Nos esforzamos por proporcionar servicios de alta calidad y ante el reporte de algún error o mal funcionamiento por parte de nuestros usuarios tomamos acciones para eliminarlo lo más pronto posible.
                    </p>
                    <h2 className="mt-2 mb-1">3-) Privacidad</h2>
                    <p className="mt-8 text-xl text-gray-500">
                        Al utilizar nuestros servicios, usted acepta nuestra política de privacidad, que se describe en detalle en nuestro sitio web.
                    </p>
                    <h2 className="mt-2 mb-1">4-) Uso de servicios</h2>
                    <p className="mt-8 text-xl text-gray-500">
                        Nuestros servicios son solo para uso comercial. Usted se compromete a no utilizar nuestros servicios para actividades ilegales o inapropiadas.
                    </p>
                    <h2 className="mt-2 mb-1">5-) Modificaciones de servicios</h2>
                    <p className="mt-8 text-xl text-gray-500">
                        Nos reservamos el derecho de modificar o discontinuar nuestros servicios siempre y cuando esto sea avisado por escrito a todos los clientes con 6 meses de antelación.
                    </p>
                    <h2 className="mt-2 mb-1">6-) Propiedad intelectual</h2>
                    <p className="mt-8 text-xl text-gray-500">
                        Los contenidos de nuestra plataforma, incluyendo, pero no limitado a, texto, gráficos, logotipos, iconos de botones, imágenes, audio clips, software, y la compilación de todo el contenido son propiedad de TECOPOS. La información generada por cada negocio es propiedad de los propios negocios.
                    </p>
                    <h2 className="mt-2 mb-1">7-) Limitación de responsabilidad</h2>
                    <p className="mt-8 text-xl text-gray-500">
                        TECOPOS está obligado a compensar al cliente por: perjuicios ocasionados debido a un mal funcionamiento del sistema y que haya dado al traste con pérdidas monetarias o incumplimiento de alguna de las cláusulas acordadas durante la firma del contrato con los clientes.
                    </p>
                    <h2 className="mt-2 mb-1">8-) Divisibilidad</h2>
                    <p className="mt-8 text-xl text-gray-500">
                        Si alguna disposición de estos términos y condiciones es considerada inválida o inaplicable, dicha disposición se considerará divisible y no afectará la validez o aplicabilidad de las disposiciones restantes.
                    </p>
                    <h2 className="mt-2 mb-1">9-) Contacto</h2>
                    <p className="mt-8 text-xl text-gray-500">
                        Si tiene alguna pregunta o inquietud sobre estos términos y condiciones, por favor póngase en contacto con nosotros a través de nuestro sitio web o llámenos al número proporcionado. Estamos disponibles para responder cualquier pregunta o inquietud que pueda tener.
                    </p>
                    <div className="mt-6">
                        <Link to="/" className="text-base font-medium text-orange-600 hover:text-orange-500">
                            Volver al Inicio
                            <span aria-hidden="true"> &rarr;</span>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    )
}
