import clsx from 'clsx';
import { ReactNode } from 'react';

interface IContainerProp {
    className?: string;
    children?: ReactNode
}

export function Container(props: IContainerProp) {
    return (
        <div
            className={clsx('mx-auto max-w-7xl px-4 sm:px-6 lg:px-8', props?.className && props.className)}

        >
            {props?.children}
        </div>
    )
}
