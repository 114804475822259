import FirstSectionFirstGif from '../../assets/gifs/2new.gif'
import FirstSectionSecoundGif from '../../assets/gifs/3new.gif'
import ImageComponent from '../misc/Image'
import { CallToAction } from './CallToAction'

export const PrimaryFeatures = () => {
    return (
        <section className="flex flex-col w-full px-4 pb-16 xl:px-52 bg-[#f4ede4]">

            <div className="flex flex-col lg:flex-row w-full">
                <div className='flex h-full lg:w-1/2 xl:jusify-center'>
                    <div className='flex sm:p-8 w-full h-[343px] md:h-[717px] lg:h-[437px] xl:h-[430px] 2xl:h-[625px]'>
                        <ImageComponent
                            src={FirstSectionFirstGif}
                            className='w-full'
                            hash={'|pN,b#-PxBaJ%1aKxaoyxuXno#axayogbHf6j[j[~pIpXAo}NHogR*WBM{nhV@off5V@jsoLayWBx^t7RPRiayWBWBofozxBV?R+kCoJfPWCj[j[E2o#s:jYoffkofaya|Rkj[j[j]j]ayoffkayadWBf+ofoekCazaeay'}
                        />
                    </div>
                </div>
                <div className="flex items-center w-full pt-4 lg:w-1/2">
                    <p className="text-lg md:text-2xl font-semibold text-end">
                        <p>
                            Ante de continuar te cuento que <span className="text-[#ea5e28] font-bold">TECOPOS </span>
                        </p>

                        <p className='mt-8'>
                            <span className='font-bold'>Es un sistema basado en la nube</span>
                            <p>

                                pues sí, con Internet
                                <p>
                                    <span className='font-bold'> (internet + cu...a = etec...a)</span>,
                                </p>
                                <p>
                                    y todos sabemos lo que eso implica.
                                    Pero no todo son cosas duras, ya que gracias a Internet podemos
                                    estar en Varadero, viajando o donde nos de la gana y a la vez
                                    controlando todo lo que pasa en nuestro ganado.
                                </p>
                            </p>
                        </p>

                        <p className='mt-8'>
                            Para que todo funcione tenemos nuestras cartas bajo la manga.
                        </p>
                        <br />
                        <p>
                            * Lo primero es que nuestra APP de venta está diseñada para si no
                            tenemos Internet, electricidad o no tenemos nada :) siga funcionado
                            la venta, algo parecido a lo que hace Whats...app que la foto no sale en
                            el momento pero al final siempre llega :).
                        </p>
                        <br />
                        <p>
                            * Lo segundo y lo más importante: nuestro piquete de soporte que siempre están chequeando como es que se está comportando la red y dispuesto para ayudarte ante cualquier duda.
                        </p>
                    </p>
                </div>
            </div>

            <div className="flex flex-col lg:flex-row w-full">
                <div className="flex items-center w-full pt-4 lg:w-1/2">
                    <p className="text-lg md:text-2xl font-semibold text-start">
                        <p className='mb-8'> <span className='text-[#ea5e28] font-bold' >Inventario</span> es una palabra que nos transmite muchos números y controles de entradas y salidas de productos, pero no te dejes intimidar...</p>
                        <p className='mb-8' >Importante, guarda esta frase ya que es la magia de todo el universo de <span className='text-[#ea5e28] font-bold'> TECOPOS</span>.</p>
                        <p className='mb-8 font-bold'><i>“Quien genera la información es quien la introduce en el sistema”</i></p>
                        <p className='mb-8'>Imagínate que el responsable del almacén tiene una pantalla(tablet) con una organización visual que la entiende hasta un abuelo de 89 años...</p>
                        <p>Bien, ahora nos vamos de viajes, si nunca has viajado no importa,
                            porque la idea que te voy a describir la entenderás igual. Cuando uno
                            entra por Aduana, ya no hay marcha atrás para poder lograr <span className='text-[#ea5e28] font-bold'>seguridad </span>
                            y <span className='text-[#ea5e28] font-bold'>control</span>, las únicas salidas es coger un avión (si es para Europa
                            mejor) o salir por un “problema gordo” pero lo que sí <span className='text-[#ea5e28] font-bold'>todo</span>, pero <span className='text-[#ea5e28] font-bold'>todo </span>
                            queda <span className='text-[#ea5e28] font-bold'>registrado</span>, hasta el perfume (la vie est bella) que te echaste...
                            así mismo trabajamos con nuestros almacenes, <span className='text-[#ea5e28] font-bold'>control total</span>, ni tú
                            mismo puedes sacar una caja de cerveza para ver el partido de
                            fútbol sin dejar huella.</p>
                    </p>
                </div>
                <div className='flex h-full lg:w-1/2 xl:jusify-center'>
                    <div className='flex sm:p-8 w-full h-[343px] md:h-[717px] lg:h-[437px] xl:h-[430px] 2xl:h-[625px]'>
                        <ImageComponent
                            className='w-full'
                            src={FirstSectionSecoundGif}
                            hash={'|qL;y%$+bYaLtQn%xut7t7$+NFfjjuofayfjfjj[~WW.xaX6V[kCRjR*R*S1s;a}ofWBj[ayjtfQ?bWVIUoMWBaefkfQj[SzxaaeWUWBayj[jtayDjNGxuoMt6j[ayj[j[M{ays:WBofj[j[aya{t7j[aeayayWBofj[j['}
                        />
                    </div>
                </div>
            </div>

            <CallToAction />
        </section>
    )
}
