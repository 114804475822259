import ThirdsSectionFirstGif from '../../assets/gifs/5new.gif'
import ThirdsSectionSecoundGif from '../../assets/gifs/6new.gif'
import ImageComponent from '../misc/Image'
import { CallToAction } from './CallToAction'

export const ThirdsFeatures = () => {
  return (
    <section className="flex flex-col w-full px-4 pb-16 xl:px-52 bg-[#f4ede4]">

      <div className="flex flex-col lg:flex-row w-full mt-8">
        <div className="flex items-center w-full pt-4 lg:w-1/2">
          <p className='text-lg md:text-2xl font-semibold text-start'>
            <p>
              Eres de los que prefieres el <span className='font-bold text-[#ea5e28]'> móvil </span> en vez de una laptop,
              o sigues la filosofía de que para todas las cosas buenas existe una
              <span className='font-bold text-[#ea5e28]' > APP </span>, ya seas de Android o iOS... si eres de ese team,
              te tenemos dos buenas noticias:
            </p>

            <p className='mt-8' >
              <span className='font-bold text-[#ea5e28]' >1.Tecopos Gestión </span> te permite tener tus negocios en el bolsillo, te da
              sensación de “yo no quiero aprender inglés, sino hablarlo”, ya que
              todo está resumido y de una manera fácil de utilizar.
            </p>

          </p>
        </div>

        <div className="w-full lg:w-1/2">
          <ImageComponent
            src={ThirdsSectionFirstGif}
            className='w-full'
            hash={'|PRe?CpIs7Y8VrWCyEt6t7krZzj[bJo~ogaKf6f+u6WEbdm%tRt7ROWVWBt9k@axjEZ#aJkXf+adx^oKWBf+Riayj[axj[vykCWBkWWYW=ofjYj[tleSV@o#S5RjofoLazj[kCkDaJogkCadjsbHM{jEozWYoLoKa}bIj['}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row w-full mt-8">
        <div className='flex h-full lg:w-1/2 xl:jusify-center'>
          <div className='flex sm:p-8 w-full h-[343px] md:h-[717px] lg:h-[437px] xl:h-[430px] 2xl:h-[625px]'>
            <ImageComponent
              src={ThirdsSectionSecoundGif}
              className='w-full'
              hash={'|ROpcE01xnX1D#t9%NRjj[-;%Mt6xs%LWBRQoff8~m.6ajxc-;RiM_t7axtKWSM}ICIBt7xuRjj?-:oeRjRRM|ofoeaya#D*IVoct4WBazj]j[f8%Nt8axbDaxofahazofWAWBs;xut7WBWBofafRls;oya_ocjuj]WBay'}
            />
          </div>
        </div>

        <div className="w-full mt-4 sm:mt-0 lg:w-1/2">
          <p className='text-lg md:text-2xl font-semibold text-end' >
            <span className="text-[#ea5e28] font-bold" >2.Tecopos Terminal</span> transforma cualquier móvil en un punto de venta,
            esto es una verdadera locura,
            imagina estar en una de esas ferias con una multitud sabrosa, hechas
            mano al cell y a vender.
          </p>
        </div>

      </div>

      <CallToAction />
    </section>
  )
}
