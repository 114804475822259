import { Link } from "react-router-dom";

export default function PrivatePolicy() {
    return (
        <div className="relative overflow-hidden bg-gray-50 py-16">
            <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
                <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-orange-600" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-orange-600" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                    </svg>
                </div>
            </div>
            <div className="relative px-6 lg:px-8">
                <div className="mx-auto max-w-prose text-lg">
                    <h1>
                        <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                            Política de privacidad
                        </span>
                    </h1>

                </div>
                <div className="prose prose-lg prose-indigo mx-auto mt-10 text-gray-500">

                    <h2>1-) Introducción </h2>
                    <p className="mt-8 text-xl text-gray-500">
                        En TECOPOS, valoramos la privacidad de nuestros clientes y nos esforzamos por proteger la información personal que recopilamos. Este documento tiene como objetivo proporcionar información detallada sobre cómo recopilamos, utilizamos y protegemos la información personal de nuestros clientes. Al utilizar nuestros servicios, usted acepta las prácticas descritas en esta política de privacidad.
                    </p>

                    <h2 className="mt-2 mb-1">2-) Información recopilada</h2>
                    <p className="mt-8 text-xl text-gray-500">
                        Toda la información generada viaja de forma encriptada y es almacenada de forma segura.
                        <ol type="a">
                            <li className="mt-8 text-xl text-gray-500">Recopilamos información personal de nuestros clientes, incluyendo, pero no limitado a, nombres, direcciones de correo electrónico, direcciones físicas y números de teléfono de los negocios asociados, datos que son indispensables para el funcionamiento del ecosistema.</li>
                            <li className="mt-8 text-xl text-gray-500">Son recopiladas métricas analíticas asociadas a rendimiento del sistema y los informes de errores.</li>
                            <li className="mt-8 text-xl text-gray-500">De igual manera quedan registrado los productos y servicios, órdenes y movimientos de inventarios que se generan en cada uno de los negocios. Los propietarios de negocios determinan el uso que tendrán estos últimos datos y son en última instancia quienes determinan su persistencia en el ecosistema TECOPOS.</li>
                        </ol>
                    </p>
                    <h2 className="mt-2 mb-1">3-) Uso de la información</h2>
                    <p className="mt-8 text-xl text-gray-500">
                        Utilizamos la información recopilada en el apartado 2 inciso a) para proporcionar atención personalizada al cliente. Utilizamos la información del apartado 2 inciso b) para mejorar nuestros productos y servicios y para personalizar la experiencia del usuario.
                    </p>
                    <h2 className="mt-2 mb-1">4-) Compartir información </h2>
                    <p className="mt-8 text-xl text-gray-500">
                        No compartimos la información personal de nuestros clientes con terceros sin su consentimiento, excepto en casos de cumplimiento legal o para proteger nuestros derechos legales.
                    </p>
                    <h2 className="mt-2 mb-1">5-) Protección de la información </h2>
                    <p className="mt-8 text-xl text-gray-500">
                        Tomamos medidas de seguridad razonables para proteger la información personal de nuestros clientes contra pérdida, robo, y uso no autorizado.
                    </p>
                    <h2 className="mt-2 mb-1">6-) Cambios a esta política de privacidad</h2>
                    <p className="mt-8 text-xl text-gray-500">
                        Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento. Cualquier cambio a esta política será publicado en nuestro sitio web.
                    </p>
                    <h2 className="mt-2 mb-1">7-) Contacto </h2>
                    <p className="mt-8 text-xl text-gray-500">
                        Si tiene alguna pregunta o inquietud sobre esta política de privacidad, por favor póngase en contacto con nosotros a través de nuestro sitio web o llámenos al número proporcionado en nuestra ficha de contacto.
                    </p>

                    <div className="mt-6">
                        <Link to="/" className="text-base font-medium text-orange-600 hover:text-orange-500">
                            Volver al Inicio
                            <span aria-hidden="true"> &rarr;</span>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    )
}
