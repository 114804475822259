import { Popover } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../containers/Container';
import { Logo } from '../molecules/Logos';
import { NavLinks } from '../molecules/NavLink';


interface IconProp {
  className: string
}

interface IMobileProp {
  href: string,
  children: ReactNode
}

function MenuIcon(props: IconProp) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" className={props.className}>
      <path
        d="M5 6h14M5 18h14M5 12h14"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function ChevronUpIcon(props: IconProp) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" className={props.className}>
      <path
        d="M17 14l-5-5-5 5"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function MobileNavLink(prop: IMobileProp) {
  return (

    <Popover.Button
      as="button"
      className="block text-base leading-7 tracking-tight text-gray-700"
    >
      <a href={prop.href}>
        {prop.children}
      </a>
    </Popover.Button>
  )
}

interface IHeader {
  navLinkHide?: boolean
}
export function Header({ navLinkHide }: IHeader) {
  return (
    <header className="bg-gray-50">
      <nav>
        <Container className="relative z-50 flex justify-between py-8">
          <div className="relative z-10 flex items-center gap-16">
            <Link to="/">
              <Logo animate={false} className="h-10 w-auto" />
            </Link>


            <div className="hidden lg:flex lg:gap-10">
              {
                !navLinkHide && (<NavLinks head={true} />)
              }
            </div>
          </div>
          <div className="flex items-center gap-6">
            <Popover className="lg:hidden">
              {({ open }) => (
                <>
                  <Popover.Button
                    className="relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 [&:not(:focus-visible)]:focus:outline-none"
                    aria-label="Toggle site navigation"
                  >
                    {({ open }) =>
                      open ? (
                        <ChevronUpIcon className="h-6 w-6" />
                      ) : (
                        <MenuIcon className="h-6 w-6" />
                      )
                    }
                  </Popover.Button>
                  <AnimatePresence initial={false}>
                    {open && (
                      <>
                        <Popover.Overlay
                          static
                          as={motion.div}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                        />
                        <Popover.Panel
                          static
                          as={motion.div}
                          initial={{ opacity: 0, y: -32 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{
                            opacity: 0,
                            y: -32,
                            transition: { duration: 0.2 },
                          }}
                          className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20"
                        >
                          <div className="space-y-4">
                            <MobileNavLink href="#features">
                              Características
                            </MobileNavLink>
                            <MobileNavLink href="#pricing">
                              Planes
                            </MobileNavLink>
                          </div>
                          {/*<div className="mt-8 flex flex-col gap-4">
                            <Button href="/login" color="outlineGray" variant="outline">
                              Log in
                            </Button>
                            <Button variant="solid" color="cyan" href="#">Download the app</Button>
                        </div>*/}
                        </Popover.Panel>
                      </>
                    )}
                  </AnimatePresence>
                </>
              )}
            </Popover>
            {/*<Button href="/login" variant="outline" color="outlineGray" className="hidden lg:block">
              Log in
            </Button>
            <Button href="#" color="orange" variant="solid" className="hidden lg:block">
              Download
                        </Button>
                        */}
          </div>
        </Container>
      </nav>
    </header>
  )
}
