import LogoTecopos from '../../assets/images/logo-tecopos.png'
import { Link } from 'react-router-dom'
interface IFooterProp {
    hideFooter?: true
}


export function Footer({ hideFooter }: IFooterProp) {

    return (
        <footer className="h-screen bg-[#f4ede4]">

            <div className='relative h-[60vh] justify-center items-center flex flex-col'>
                <div className='bg-[#541554] h-[100%] w-full rounded  absolute z-0' ></div>
                <div className='flex flex-col w-3/4 sm:w-1/2 z-10 h-full items-center justify-center mt-8 '>
                    <h1 className='text-white text-2xl sm:text-4xl font-bold text-center' >Consejo no solicitado :</h1>
                    <br/>
                    <h1 className='text-white text-2xl sm:text-4xl font-bold text-center' >“Digitalízate o sigue perdiendo dinero” </h1>

                    <div className='w-full flex flex-col gap-4 justify-center pb-8 '>
                        <h2 className='text-white text-xl sm:text-2xl font-bold text-center mb-8'><i>Registramos solo 17 clientes al mes,</i></h2>
                        <button  onClick={()=> window.open(`https://api.whatsapp.com/send?phone=${+5350649337}&text=${"Hola Tecopos"}`) }
                    className="bg-white  text-[#541554] text-base md:text-xl font-semibold h-[5rem] rounded-md">UNIRME AHORA</button>
                      
                    </div>
                </div>
            </div>

            <div className='h-[40vh] w-full flex flex-col md:flex-row justify-center items-center'>

                <div className='flex w-1/2 md:w-1/6  justify-center items-center'>
                    <div className='ml-8'>
                        <img src={LogoTecopos} alt="tecopos-logo" />
                    </div>
                </div>


                <div className='flex md:w-1/2 w-2/3 justify-center items-center  ' >
                    <div className='flex w-full mt-16 md:mt-0 flex-col md:w-3/5 gap-4'>
                        <div className='flex flex-col text-end sm:text-start sm:flex-row text-lg sm:text-md justify-end font-bold gap-1 sm:gap-4 text-[#541554]' >
                            <Link className='hover:text-[#a85abe]' to={'/conditions'} >Términos y condiciones</Link>
                            <Link className='hover:text-[#a85abe]' to={'/policy'} >Política de privacidad</Link>
                        </div>

                        <p className='text-sm sm:text-sm text-end text-[#541554c2] font-bold' >
                            © Copyright { new Date().getFullYear() }. Todos los derechos reservados
                        </p>
                    </div>
                </div>

            </div>

        </footer >
    )
}