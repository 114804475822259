import FifthSectionGif from '../../assets/gifs/8new.gif'
import ImageComponent from '../misc/Image'
import { CallToAction } from './CallToAction'

export function FifthFeatures() {
    return (
        <section className="flex flex-col w-full px-4 pb-16 xl:px-52 bg-white" >
            <div className='mt-8'>
                <p className='text-lg md:text-2xl font-semibold text-center'>
                    <p className='mb-8' >Hace ya bastante tiempo uno de nuestros clientes nos decía:</p>
                    <p><i className='font-bold'>“<span className='text-[#ea5e28]' >Tecopos</span> es una locura, ya puedo ver con transparencia lo que sucede, todo ese rollo de las operaciones de almacenes, las ventas, los
                        tipos de moneda que entran en caja...”</i>
                    </p>
                    <br />
                    <p>
                        Bueno y más cosas que sabemos y si no lo sabes ya a esta altura del partido sabes dónde tocar.
                    </p>
                    <br />
                    <p>
                        Seguimos con el hilo:
                        <br />
                        <span className='font-bold'> ...entonces <span className='text-[#ea5e28]'> tecoposes </span> ustedes ¿pueden seguir la traza del dinero, todas las operaciones que hacen mis administrativos para comprar mercancía, pagos menores, todo ese mundillo contable pero que se vea como una de esas app de bancas que existe afuera?”... </span>
                        <br /> <br />
                        La respuesta para esto fue clara :) esto se resuelve con  <span className='font-bold text-[#ea5e28]'> cuentas bancarias </span> como el transfermó...vil pero <br /><span className='font-bold text-[#ea5e28]'>con toque...</span>
                    </p>

                </p>
            </div>

            <div className='h-[228px] md:h-[490px] lg:h-[580px] xl:h-[595px] 2xl:h-[900px] ' >
                <ImageComponent
                    src={FifthSectionGif}
                    className='w-full'
                    hash={'|URCufH?x]?^%#W=D%IURPQ-a0o}k=g3e.o0oLjZ-oxabvIpM{jE%2x]t8s.V@kCozkCaeV@WBa}?bWBWBayjtaxWAaea#yDbcadjEjZf6V@aef+tlX8aKniofj[RjV@WBpIo}V@VsWBj]oLjZkCE2tRjFaKaefkj[f6kC'}
                />
            </div>

            <CallToAction color='beige' />
        </section>
    )
}
